$green: #00964B;
$darkgreen: #005238;
$orange: #EA5B0C;
$lightgreen: #EBFFEB ;
$padding-m: 30px; 
$padding-l: 60px; 
$border-radius: 8px;

@mixin gradient {
    background: rgb(75,200,50);
    background: linear-gradient(270deg, rgba(75,200,50,1) 0%, rgba(0,150,75,1) 100%);
}

@mixin radialGradient {
    background: rgb(75,200,50);
    background: radial-gradient(circle, rgba(75,200,50,1) 20%, rgba(0,150,75,1) 100%);
}