.question-container {
    width: 100%;

    ul {
        list-style-type: none;
        padding-left: 0;
        width: 100%;

        &.flex-items {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            column-gap: 25px;

            li {
                align-items: center;
                justify-content: center;

                .checkbox {
                    display: none;

                }
            }
        }

        li {
            display: flex;
            align-items: flex-start;
        }
    }

    &__answer {
        border: 1px solid $green;
        border-radius: $border-radius;
        padding: 7px 10px;
        margin-bottom: 25px;
        color: #fff;
        line-height: 1.18;
        cursor: pointer;
        transition: .3s ease;
        width: 100%;

        &.active {
            & .checkbox {
                background-color: $orange;
            }

            &.custom-text {
                & .checkbox {
                    border: none;
                }
            }
        }

        & .checkbox {
            display: block;
            min-width: 20px;
            width: 20px;
            height: 20px;
            background-color: #fff;
            margin-right: 15px;
            border-radius: 3px;
            border: 1px solid $darkgreen;
            background-color: $lightgreen;
            transition: .1s ease;
        }

        &.custom-checkbox.active {
            background-color: $darkgreen;
           
        }

        &.custom-radio.active {
            background-color: $darkgreen;

           
        }

        &.custom-text {
            background-color: $lightgreen;

            textarea {
                background-color: $lightgreen;
                border: none;
                min-height: 131px;
                width: 100%;
                line-height: 1.4;

                &::placeholder {
                    color: $darkgreen;
                }

                &:focus {
                    outline: none
                }
            }

            .pencil {
                margin-right: 5px;
            }
        }

    }

    .button {
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        width: 154px;
        cursor: pointer;
        float: right;
        padding: 5px 15px;
        font-family: 'Palanquin', sans-serif;
        font-weight: 700;
        transition: .1s ease;

        &.disabled {
            pointer-events: none;
            opacity: .4;
        }
    }
}