.introtext {
    & .main-title {
        display: flex;
        flex-direction: column;
        line-height: 1;
        margin-top: -3px;
    }

    .duration-data {
        list-style-type: none;
        padding-left: 0;
        color: #fff;
        font-family: 'Palanquin', sans-serif;
        font-weight: 700;
        display: flex;
        

        li {
            display: flex;
            &:first-of-type {
                margin-right: 20px;
            }
            img {
                width: 15px;
                margin-right: 5px;
            }
        }
    }

    p {
        max-width: 300px;
        line-height: 1.4;
        &:last-of-type {
            margin-top: 20px
        }

        span {
            cursor: pointer;
        }
    }
}