* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
    width: 100%;
}



body {
    height: 100%;
    font-size: 17px;
    @include radialGradient();
    font-family: 'Palanquin', sans-serif;
    font-weight: 400;

}

#root {
    height: 100%;
    overflow-y: scroll ;
}

textarea {
    font-family: 'Palanquin', sans-serif;  
    font-size: 1rem;
    color: $darkgreen;
}

.app {
    position: relative;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    height: 100%;

    @media(max-width: 768px) {
        justify-content: flex-start;
    }
}

h1 {
    font-size: 2.8rem;
    color: #fff;
    span {
        color: $darkgreen
    }
    margin-bottom: 20px;
}

h2 {
    font-size: 2.117rem;
}

h3 {
    font-size: 1.294;
}


h1,h2,h3 {
    font-family: 'Palanquin', sans-serif;
    font-weight: 700;


}

p {
    color: #fff;
}




.container {
    padding: 0 $padding-l;
    @media(max-width: 768px) {
        padding: 0 30px
    }
}

.skip-button {
    position: absolute;
    z-index: 2;
    bottom: 60px;
    right: 60px;
    background: #1E8F40;
    color: #fff;
    padding: 5px 15px;
    border-radius: 6px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        @include gradient;
        
        z-index: -1;
        border-radius: inherit;
    }
    &:after {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        @include gradient;
        background: #1E8F40;

        z-index: -1;
        border-radius: inherit;
    }

    img {
        width: 15px;
        margin-left: 10px;
        margin-top: 2px;
    }

    @media(max-width: 768px) {
        position: relative;
        bottom: unset;
        right: unset;
        float: right;

        border-radius: 8px;
        width: 154px;
        cursor: pointer;
        padding: 5px 15px;
    }
}

.row {
    max-width: 1440px;
    width: 100%;
    display: block;
    margin: 0 auto;

    &--full {
        max-width: 100%;
    }
}

.nav {
    position: relative;
    z-index: 2;
    padding: $padding-m 0;
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.main {   

    @media(max-width: 768px) {
        .container {
            padding: 0;
        }
    }

    & .row {
        height: auto;
        align-items: center;
        justify-content: center;
    }
    &__inner {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        
        .left {
            position: relative;
            border-radius: $border-radius;

            .video-wrapper {
                -webkit-box-shadow: 0px 0px 17px 5px rgba(0,0,0,0.24); 
                box-shadow: 0px 0px 17px 5px rgba(0,0,0,0.24);
                border-radius: $border-radius;
            }

            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: (1 / 1) * 100%;
            }
            &__inner {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;

              &__center {
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .sound {
                    position: absolute;
                    bottom: -30px;
                    transform: translateY(100%);
                    border-radius: 10px;
                    background-color: transparent;
                    font-size: 16px;
                    text-align: center;
                    color: #fff;
                    background: $darkgreen;
                    display: flex;
                    border-radius: 8px;
                    width: 180px;
                    cursor: pointer;
                
                    padding: 5px 15px;
                    white-space: nowrap;

                    img {
                        width: 28px;
                        margin-left: 10px;
                        margin-top: 2px;
                    }
                }
              }

              & .playbutton {
                position: relative;
                color: #fff;
                background-color: $orange;
                width: 103px;
                height: 103px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 103px;
                font-family: 'Palanquin', sans-serif;
                font-weight: 700;
                font-size: 1.65rem;
                cursor: pointer;
                transition: .3s ease;
                transition-delay: .2s;

                &.remove {
                    
                    opacity: 0;
                }

                span {
                    transform: translateY(-5px);
                }
                

                &:after {
                    content: '';
                    position: absolute;
                    left: -10px;
                    top: -10px;
                    width: 121px;
                    height: 121px;
                    border-radius: 121px;
                    z-index: 1;
                    border: 1px solid #fff;
                    transform-origin: center center;
                    animation: buttonPulse 1s ease-in-out infinite alternate;
                }
              }

              iframe {
                position: relative;
                z-index: 1;
                width: 100%;
                
                border-radius: $border-radius;
                display: block;
      

                @media(max-width: 768px) {
                    border-radius: 0;
                }
              }

          
            }
        }
        .right {
            position: relative;
            height: 100%;
            // width: 50%;
            padding: $padding-l;
            background: rgb(0,82,56);
            background: linear-gradient(270deg, rgba(0,82,56, .4) 0%, rgba(0,82,56,0) 100%);
            border-radius: $border-radius;
            display: flex;
            align-items: center;
            z-index: 1;
        }

        @media(max-width: 768px) {
            grid-template-columns: 1fr;
            max-height: unset;

            .right {
                background: transparent;
                padding: 30px;
                flex-direction: column;
                align-items: flex-end;
                min-height: 300px;

                .introtext {
                    width: 100%;
                }
            }

            .left {
                &__inner {
                    max-height: 655px;
                }
            }
        }
    }

    iframe {
        border-radius: $border-radius;
    }
}

.progress {
    position: relative;
    right: 0;
    background-color: $lightgreen;
    height: 10px;
    width: 250px;
    overflow: hidden;
    border-radius: 10px;

    &__bar {
        position: absolute;
        left: 0;
        height: 100%;
        width: 10px;
        background-color: $orange;
        transition: .4s ease-in-out;
    }

    @media(max-width: 768px) {
        display: block;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 40px;
    }
}

.plate-container {
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    &__plate {
        position: relative;
        z-index: 2;
        max-width: 500px;
        width: 100%;
    }
    &__pattern {
        position: absolute;
        right: 0;
        z-index: 1;
        max-width: 150px;
    }
}

footer {
    padding: $padding-m 0;
    ul {
        display: flex;
        padding-left: 0;
        list-style-type: none;
        color: #fff;

        @media(max-width: 768px) {
            justify-content: space-between;

            li {
                font-size:0.705rem;
            }
        }

        a {
            color: #fff;
            text-decoration: none;
        }

        li {
            &:last-of-type {
                margin-left: 50px;
            }
        }
    }
}

.button {
    color: #fff;
    padding: 10px 20px;
    background-color: $orange;
}

.mobile {
    display: none;
}

.download-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    img {
        display: block;
        margin: 0 auto;
        margin-bottom: 30px;
        max-width: 300px;
        width: 100%;
    }
    
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 70px;
        border: 1px solid rgba(255,255,255,0.3);
        border-radius: 9px;

        h2 {
            text-align: center;
            color: #fff
        }
    }

    form {
        margin-top: 20px;
        width: 100%;
        .input-wrapper {
            display: flex;
            flex-direction: column;
        }
        input {
            padding: 10px 20px;
            border-radius: 6px;
            border: none;
            outline: none;
        }
        button {
            display: inline-block;
            margin-top: 10px;
            cursor: pointer;
        }
    }
    
    a, button {
        color: #fff;
        padding: 10px 20px;
        background-color: #EA5B0C;
        border: none;
        display: block;
        margin-top: 20px;
        text-decoration: none;
        border-radius: 6px;
    }
}

@media(max-width: 768px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
}


