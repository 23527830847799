﻿.end-title {
    h2 {
        font-size: 2.823rem;
        color: #fff;
        line-height: 1.2;
    }
    p {
        line-height: 1.41;
        margin-bottom: 30px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &.negative {
        h2 {
            margin-bottom: 20px;
        }
    }

    & .btn {
        background-color: $orange;
        color: #fff;
        padding: 2px 20px 5px;
        border-radius: 4px;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 30px;
        transition: .3s ease;

        &:hover {
            background-color:#d1530e;
        }
    }

    fieldset {
        position: relative;
        border-radius: 8px;
        border: 1px solid $darkgreen;
        margin-bottom: 10px;      
        padding: 0 20px 0 20px;  

        legend {
            padding: 0 5px;
            color: $darkgreen;
        }

        h3 {
            color: #fff;
            transform: translateY(-5px);
            font-size: 1.7rem;
        }

       

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}